import * as React from "react";
import { Charge, ChargeCategories, ChargeStatus, Transaction } from '../../../../graphql/types/checkoutOrders';
import { ClipboardButton, ExpandableItemContainer, ExpandableItemContent, ExpandableItemHeader, Chevron, Info, NormalButton } from './styled';
import { Divider, Grid } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import moment from 'moment';
import toReal from '../../../../helpers/toReal';
import { uppercaseFirstLetter } from '../../../../helpers/uppercasefirstLetter';
import { parseTransactionStatus, TransactionStatusColor } from '../../../../helpers/charges';
import { gray16, gray6 } from '../../../../style/colors';
import { ChargeStatusBadge } from './chargeStatusBadge';
import { copyToClipboard } from '../../../../helpers/clipboard';
import { toast } from 'react-toastify';
import { RetryDialog } from './retryDialog';
import { IQueryOrderByShortId } from '../../../../graphql/queries/checkoutOrders';
import { getShowDueDate } from './info.charges';

type ChargeItemProps = {
  data: Charge;
  order: IQueryOrderByShortId['searchOrderById'];
  isMobile: boolean;
  last: boolean;
  header?: boolean;
  total: number
  type: 'delayed' | 'normal'
}

const parserCategory = {
  [ChargeCategories.ALLU_CARE]: 'Allu care',
  [ChargeCategories.ALLU_SHIELD]: 'Allu shield',
  [ChargeCategories.PARCEL]: 'Parcela',
  [ChargeCategories.SETUP]: 'Parcela',
  [ChargeCategories.FINE_CANCELLATION]: 'Multa de cancelamento',
}

const getBackgroundColor = (type: 'delayed' | 'normal') => {
  return type === 'delayed' && '#F4433614'
}

export const ChargeItem = (props: ChargeItemProps) => {
  const [isExpanded, setExpanded] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const backgroundColor = getBackgroundColor(props.type)
  const expandable = React.useMemo(() => props.data.transactions.length > 0, [props.data])

  const toggle = () => {
    if (!expandable) return;
    const newValue = !isExpanded;
    setExpanded(newValue);
  };

  const toggleDialog = () => {
    setOpenDialog(!openDialog)
  }

  React.useEffect(() => {
    setExpanded(false)
  }, [props.isMobile])

  return (
    <>
      <RetryDialog isMobile={props.isMobile} open={openDialog} handleClose={toggleDialog} charge={props.data} order={props.order} />
      {props.header && !props.isMobile && (
        <>
          <ExpandableItemContainer key={`header::${props.isMobile}`} style={{ fontFamily: 'Lato' }}>
            <ExpandableItemHeader>
              <Grid container >
                <Grid item xs={11}>
                  <Grid container alignItems={'center'}>
                    <Grid item md={3}><Info small bold>Parcela</Info></Grid>
                    <Grid item md={3}><Info small bold>Vencimento</Info></Grid>
                    <Grid item md={3}><Info small bold>Valor</Info></Grid>
                    <Grid item md={3}><Info small bold>Status</Info></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ExpandableItemHeader>
          </ExpandableItemContainer>
          <Divider style={{ marginTop: '1px', marginBottom: '2px' }} />
        </>
      )}
      <ExpandableItemContainer key={`${props.data.id}::${props.isMobile}::${props.type}`} isOpen={isExpanded} bgColor={backgroundColor} expandable={expandable} style={{ fontFamily: 'Lato' }}>
        <ExpandableItemHeader onClick={toggle} isOpen={isExpanded}>
          <Grid container alignItems={'center'}>
            <Grid item xs={11}>
              {props.isMobile && <Mobile {...props} />}
              {!props.isMobile && <Web {...props} />}
            </Grid>
            {expandable && (
              <Chevron isOpen={isExpanded}>
                <KeyboardArrowDown style={{ color: gray6 }} />
              </Chevron>
            )}
          </Grid>
        </ExpandableItemHeader>
        <ExpandableItemContent isOpen={isExpanded}>
          {props.isMobile && <TransactionsMobile {...props} toggleDialog={toggleDialog} /> }
          {!props.isMobile && <TransactionsWeb {...props} toggleDialog={toggleDialog} /> }
        </ExpandableItemContent>
      </ExpandableItemContainer>
      {!props.isMobile && !props.last && <Divider style={{ marginTop: '1px', marginBottom: '2px' }} />}
    </>
  )
}

const Web = (props: ChargeItemProps) => {
  const other = ![ChargeCategories.PARCEL, ChargeCategories.SETUP].includes(props.data.category);
  const showDueDate = getShowDueDate(props)

  const handleLink = () => {
    if (props.data.status === ChargeStatus.PAID) {
      toast.success('pagamento já realizado')
      return
    }
    copyToClipboard(props.data.link);
  }

  return (
    <Grid container alignItems={'center'}>
      <Grid item md={3}>
        {!other && <Info>{props.data.installment + ' de ' + props.total}</Info>}
        {other && <Info>{parserCategory[props.data.category]}</Info>}
      </Grid>
      {!other && <Grid item md={3}>
        {!showDueDate && <Info>-</Info>}
        {showDueDate && (
          <Info>{
            props.data.dueDate 
            ? moment(props.data.dueDate).format('DD/MM/YYYY')
            : props.data.paymentDate ? moment(props.data.paymentDate).format('DD/MM/YYYY')
            : ''
          }</Info>
        )}
      </Grid>}
      {other && props.data.link && <Grid item md={3}><ClipboardButton onClick={handleLink}>COPIAR LINK</ClipboardButton></Grid>}
      <Grid item md={3}><Info>{toReal(props.data.totalValue / 100)}</Info></Grid>
      <Grid item md={3}><ChargeStatusBadge status={props.data.status} /></Grid>
    </Grid>
  )
}

const Mobile = (props: ChargeItemProps) => {
  const other = ![ChargeCategories.PARCEL, ChargeCategories.SETUP].includes(props.data.category);
  const showDueDate = getShowDueDate(props)

  const handleLink = () => {
    if (props.data.status === ChargeStatus.PAID) {
      toast.success('pagamento já realizado')
      return
    }
    copyToClipboard(props.data.link);
  }
  
  return (
    <>
      <Grid container alignItems={'center'}>
        <Grid item xs={8} display={'flex'} direction={'column'}>
          {other && <Info>{parserCategory[props.data.category]}</Info>}
          {props.data.dueDate && showDueDate && <Info>Vencimento: {moment(props.data.dueDate).format('DD/MM/YYYY')}</Info>}
          {!props.data.dueDate && props.data.paymentDate && <Info>Data do pagamento: {moment(props.data.paymentDate).format('DD/MM/YYYY')}</Info>}
          <Info>Valor: {toReal(props.data.totalValue / 100)}</Info>
        </Grid>
        <Grid item xs><ChargeStatusBadge status={props.data.status} /></Grid>
      </Grid>
      {other && props.data.link && <ClipboardButton onClick={handleLink} style={{ marginTop: '12px' }}>COPIAR LINK</ClipboardButton>}
    </>
  )
}

const TransactionsWeb = (props: ChargeItemProps & { toggleDialog: () => void }) => {
  const card = (transaction: Transaction) => {
    const brand = transaction?.cardBrand || '-'
    const lastDigits = transaction?.cardLastDigits || '-'
    if (transaction) {
      return <Info style={{ fontSize: '14px' }}>{uppercaseFirstLetter(brand)} final {lastDigits}</Info>
    }
  }

  const transactions = React.useMemo(() => props.data.transactions || [], [props.data])

  return (
    <Grid key={`${props.data.id}::${props.isMobile}`} style={{ padding: '15px' }}>
      <Info bold>Histórico da cobrança</Info>
      <Grid container alignItems={'center'} style={{ marginTop: '20px', marginBottom: '10px', fontSize: '14px' }}>
        <Grid item md={3}><Info style={{ fontSize: '14px' }} bold>ID da transação</Info></Grid>
        <Grid item md={3}><Info style={{ fontSize: '14px' }} bold>Realizada em</Info></Grid>
        <Grid item md={3}><Info style={{ fontSize: '14px' }} bold>Cartão</Info></Grid>
        <Grid item md={3}><Info style={{ fontSize: '14px' }} bold>Status pagamento</Info></Grid>
      </Grid>
      {transactions.map((transaction, index) => (
        <Grid container key={`${transaction.id}::${props.type}::${props.isMobile}`} style={{ fontSize: '14px' }}>
          <Grid item md={3}><Info style={{ fontSize: '14px' }}>{transaction.externalId}</Info></Grid>
          <Grid item md={3}><Info style={{ fontSize: '14px' }}>{moment(transaction.attemptDate).format('DD/MM/YYYY [às] HH:MM:SS')}</Info></Grid>
          <Grid item md={3}>{card(transaction)}</Grid>
          <Grid item md={3}><Info style={{ fontSize: '14px' }} color={TransactionStatusColor[transaction.status] || gray16}>{parseTransactionStatus(transaction.status)}</Info></Grid>
          {(index !== props.data.transactions.length - 1) && (
            <Grid item xs={12}><Divider  style={{ marginTop: '12px', marginBottom: '12px' }} /></Grid>
          )}
        </Grid>
      ))}
      {props.data.status === ChargeStatus.FAILED && (
        <Grid container style={{marginTop: '12px'}}>
          <NormalButton onClick={props.toggleDialog} style={{ marginLeft: 'auto' }}>Fazer pagamento</NormalButton>
        </Grid>
      )}
    </Grid>
  )
}

const TransactionsMobile = (props: ChargeItemProps & { toggleDialog: () => void }) => {
  const card = (transaction: Transaction) => {
    const brand = transaction?.cardBrand || '-'
    const lastDigits = transaction?.cardLastDigits || '-'
    if (transaction) {
      return <Info style={{ fontSize: '14px', fontWeight: 500 }}>Cartão: {uppercaseFirstLetter(brand)} final {lastDigits}</Info>
    }
  }

  const transactions = React.useMemo(() => props.data.transactions || [], [props.data])

  return (
    <Grid key={`${props.data.id}::${props.isMobile}`} style={{ padding: '15px' }}>
      <Info bold>Histórico da cobrança</Info>
      <br />
      <br />
      {transactions.map((transaction, index) => (
        <Grid container key={`${transaction.id}::${props.type}::${props.isMobile}`}>
          <Grid item xs={12}><Info style={{ fontSize: '14px', fontWeight: 500 }}>Transação: {transaction.externalId}</Info></Grid>
          <Grid item xs={12}><Info style={{ fontSize: '14px', fontWeight: 500 }}>Realizada em: {moment(transaction.attemptDate).format('DD/MM/YYYY [às] HH:MM:SS')}</Info></Grid>
          <Grid item xs={12}>{card(transaction)}</Grid>
          <Grid item xs={12}><Info style={{ fontSize: '14px', fontWeight: 500 }}>Status pagamento: </Info><Info style={{ fontWeight: 500 }} color={TransactionStatusColor[transaction.status] || gray16}>{parseTransactionStatus(transaction.status)}</Info></Grid>
          {(index !== props.data.transactions.length - 1) && (
            <Grid item xs={12}><Divider  style={{ marginTop: '12px', marginBottom: '12px' }} /></Grid>
          )}
        </Grid>
      ))}
      {props.data.status === ChargeStatus.FAILED && (
        <Grid container style={{marginTop: '12px'}}>
          <NormalButton onClick={props.toggleDialog}>Fazer pagamento</NormalButton>
        </Grid>
      )}
    </Grid>
  )
}