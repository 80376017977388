import * as React from "react";
import { IPropsWithMode } from './info';
import { Charge, ChargeTypes, State } from '../../../../graphql/types/checkoutOrders';
import { Info } from './styled';
import { Pagination } from './pagination';
import { ChargeItem } from './charge';
import { Grid } from '@mui/material';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import { IQueryOrderByShortId } from '../../../../graphql/queries/checkoutOrders';

export const getShowDueDate = (props: { order: IQueryOrderByShortId['searchOrderById'] }) => {
  const orderDelivered = props.order.checkoutOrderProducts[0]?.state === State.pedidoEntregue;
  const orderReturned = props.order.checkoutOrderProducts[0]?.state === State.produtoDevolvido;

  return orderDelivered || orderReturned
}

export const Charges = (props: IPropsWithMode) => {
  const { order, isMobile } = props;
  const [dataToShow, setDataToShow] = React.useState<Charge[]>([])
  const showDueDate = getShowDueDate(props)

  const data = React.useMemo(() => {
    const total = order.subscription.cycleQuantity
    const delayed = order.charges.filter(charge => charge.late && charge.type === ChargeTypes.PARCEL)
    const parcels = order.charges.filter(charge => charge.type === ChargeTypes.PARCEL)
    const others = order.charges.filter(charge => charge.type !== ChargeTypes.PARCEL)
    return { total, delayed, parcels, others }
  }, [order])
  
  return (
    <>
      {data.others.length > 0 && (
        <>
          <Info style={{ fontWeight: 500, marginBottom: '20px', fontSize: '21px' }}>{`Outros pagamentos (${data.others.length})`}</Info>
          {data.others.map((charge, index) => 
            <ChargeItem
              key={`${index}::${charge.id}::others`}
              isMobile={isMobile}
              data={charge}
              order={props.order}
              last={index === data.others.length - 1}
              total={data.total}
              type='normal'
            />
          )}
          <Grid style={{ marginBottom: '48px' }} />
        </>
      )}
      {data.delayed.length > 0 && (
        <>
          <Info style={{ fontWeight: 500, fontSize: '21px' }}>{`Faturas em atraso (${data.delayed.length})`}</Info>
          <Info style={{ marginTop: '-5px', marginBottom: '20px' }}>Você tem algumas faturas atrasadas. Faça o pagamento o quanto antes para ficar em dia com sua assinatura.</Info>
          {data.delayed.map((charge, index) => 
            <ChargeItem
              key={`${index}::${charge.id}::delayed`}
              isMobile={isMobile}
              data={charge}
              order={props.order}
              last={index === data.delayed.length - 1}
              total={data.total}
              type='delayed'
            />
          )}
          <Grid style={{ marginBottom: '48px' }} />
        </>
      )}
      <Info style={{ fontWeight: 500, marginBottom: '20px', fontSize: '21px' }}>{`Faturas ${data.delayed.length > 0 ? '(todas)' : ''}`}</Info>
      {!showDueDate && 
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          padding: '8px', 
          backgroundColor: '#ffac80', 
          borderRadius: '8px', 
          color: 'white',
          marginTop: '-5px',
          marginBottom: '20px'
        }}>
          <ErrorOutlineOutlined style={{ marginRight: '8px' }} />
          <Info>As datas de <strong>vencimento</strong> da sua assinatura serão atualizadas assim que o seu <strong>produto for entregue</strong>. Fique atento para não perder nenhuma data importante!</Info>
        </div>
      }
      {dataToShow.map((charge, index) => 
        <ChargeItem
          key={`${index}::${charge.id}::normal`}
          isMobile={isMobile}
          data={charge}
          order={props.order}
          header={index===0}
          last={index === dataToShow.length - 1}
          total={data.total}
          type='normal'
        />
      )}
      <Pagination data={data.parcels} setData={setDataToShow}  />
      <Grid style={{ marginBottom: '88px' }} />
    </>
  )
}